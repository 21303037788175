/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Input {
  font-size: 1.4rem;
  position: relative;
}
.Input select {
  height: 5rem;
}
.Input .label {
  display: inline-block;
  width: 40%;
  position: relative;
}
.Input .tooltip-icon {
  max-width: 1rem;
}
.Input.valid > div.virtualLabel,
.Input.valid > label {
  outline: 0.1rem solid #aae053;
}
.Input.disabled > label {
  margin-bottom: 0;
}
.Input.disabled > label:after {
  content: ' ';
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.05);
}
.Input.invalid > div.virtualLabel,
.Input.invalid > label {
  outline: 0.1rem solid #ff486b;
}
.Input:focus-within > div.virtualLabel,
.Input:focus-within > label {
  outline: 0.1rem solid #35c5ff;
}
.Input div.virtualLabel,
.Input label {
  background: #fff;
  padding: 0.8rem;
  text-align: left;
  color: #745a9e;
  font-weight: 600;
  width: 100%;
  padding: 1.2rem;
  outline: 0.1rem transparent #ff486b;
}
.Input div.virtualLabel input,
.Input label input {
  border: 0;
  display: inline-block;
  width: 60%;
  padding: 1.2rem;
}
.Input div.virtualLabel input:focus,
.Input label input:focus {
  outline: none;
}
.Input .error {
  padding: 1rem;
  color: #ff486b;
  font-weight: 600;
}
